/**
 * HistoryAll component is using style from this place
 */
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
    padding: theme.spacing(0)
    },
    filterBody: {
        padding: "10px"
    },
    maiBody: {
        padding: "10px"
    },
    gridBody: {
        padding: "5 2 2 0"
    },
    youAreAwesomeTableGridBody: {
        padding: "10px"
    },
    cardHeader: {
        backgroundColor: '#485ED5',
        background: 'linear-gradient(60deg, #3f51b5, #4e66e6)',
        width: '100%',
        minHeight: '50px',
        borderRadius: "5px"
    },
    headerText: {
        color: '#FFFFFF',
        marginTop: '10px',
        minHeight: 'auto',
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 'bold',
        marginBottom: '3px',
        textDecoration: 'none',
        textAlign: 'left'
    },
    headerDateText: {
        color: '#FFFFFF',
        marginTop: '10px',
        minHeight: 'auto',
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 'normal',
        marginBottom: '3px',
        textDecoration: 'none',
        textAlign: 'left'
    },
    cardBody: {
        width: '100%',
        minHeight: "600px",
        backgroundColor: "#F1F1F4",
    },
    tableCell: {
        color: 'inherit',
        fontSize: '11px',
    
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    
        width: '20px',
        maxWidth: '20px',
        height: "48px",
        maxHeight: "48px"
      },
      pad0: {
        padding: '0 !important'
      },
      tableHeader: {
        
      },
      tableHeaderRow: {
        verticalAlign: "top"
      },
      tableHeadCellDetail: {
        color: 'inherit',
        fontSize: '12px',
        fontWeight: 'bold',
        padding: '5px !important',
        textAlign: "left",
        width: "100px",
        lineHeight: "1.2rem", 
        backgroundColor: "#DBDBDB"
      },
      tableCellDetail: {
        color: 'inherit',
        fontSize: '14px',
        textAlign: "left",
        padding: '5px !important'
      },
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
      tableEmptyCellDetail: {
        color: 'inherit',
        fontSize: '14px',
        textAlign: "center",
        padding: '5px !important',
        height: "25px !important",
        margin: "0px !important"
      },
      tableHeadFirstCellDetail: {
        color: 'inherit',
        fontSize: '12px',
        fontWeight: 'bold',
        padding: '5px !important',
        textAlign: "left",
        width: "100px",
        lineHeight: "1.2rem", 
        backgroundColor: "#FFFFFF"
      },
      expressionCell: {
        padding: '5px !important',
        borderBottom: "none"
      },
      graphDiv: {
        padding: "0 0 0 0 !important",
      },
      tableTotalCellDetail: {
        color: 'inherit',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '5px !important',
        textAlign: "left",
        width: "100px",
        lineHeight: "1.2rem", 
        backgroundColor: "#DBDBDB"
      },
}))
