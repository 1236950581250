import React, { useEffect, useState } from "react"
import { useLocation } from 'react-router-dom'

import { Helmet } from "react-helmet"
import moment from "moment"
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import { usePDF, Margin } from "react-to-pdf"
import ToastElement from "../../../components/Shared/Elements/ToastElement"

import { 
    Button,
    Grid,
} from "@material-ui/core"
import { useStyles } from "./style"
import FilterReport from "../FilterReport"
import { getReportAnalyticsService, 
    getYouAreAwesomeDetailDataService, 
    getReportFilterFetchData,
    getReportFilterInitFetchData,
    getObFeedbackDetailDataService,
    getSubDepartmentFetchData,
    getFeelingAtHomeDetailDataService,
    getDiversityDetailDataService,
    getExitAnalysisDetailDataService,
    getGrossOfferDataService,
    getNetOfferDataService
} from "../fetchData"
import { 
    getDesignationListByGradeNameFetchData,
} from '../../Shared/FetchData'
import HeaderLayout from "../HeaderLayout"
import Loader from "../../../components/Loader"
import YouAreAwesomeCard from "./YouAreAwesomeCard"
import YouAreAwesomeData from "./YouAreAwesomeData"
import FifteenFeedbackCard from "./FifteenFeedbackCard"
import FifteenFeedbackData from "./FifteenFeedbackData"
import SixtyFeedbackCard from "./SixtyFeedbackCard"
import SixtyFeedbackData from "./SixtyFeedbackData"
import FeelingAtHomeCard from "./FeelingAtHomeCard"
import FeelingAtHomeData from "./FeelingAtHomeData"
import DiversityCard from "./DiversityCard"
import DiversityData from "./DiversityData"
import ExitAnalysisCard from "./ExitAnalysisCard"
import ExitAnalysisData from "./ExitAnalysisData"
import MainDivOnDownload from "./MainDivOnDownload"

import NetOfferData from "./NetOfferData"
import GrossOfferData from "./GrossOfferData"

import NetOfferCard from "./NetOfferCard"
import GrossOfferCard from "./GrossOfferCard"
import { fetchCompareData, getComparisonDates } from "./Comparison"
import { getReportHtml, handleDownloadReport } from "../DownloadReport"


const youAreAwesomeCols = {
    tabp_name: "TABP",
    department_name: "Department",
    function_name: "Sub Department",
    grade: "Grade",
    designation: "Designation",
    location_name: "Location",
    rm_name: "Reporting Manager",
    mappedHeadCount: "Actual Headcount Mapped",
    mappedManagerHeadCount: "Managers Mapped to RHR",
    totalNoOfCardExchanged: "Total No of Cards Exchanged",
    percentOfCardExchanged: "% Cards Exchanged",
    uniqueCardSenderCount: "Unique Cards Senders",
    cardSentByManagerToDRCount: "Cards Sent by Managers to Direct Reportees",
    cardSentByUniqueManagerToDRCount: "Unique Managers who gave cards to their DRs",
    cardSentBySpecifiedDepartment: "Cards Sent By HR & L&D"
}

const EMP_DOJ_START_DATE = '20080101'
const HR_DASHBOARD_START_DATE = '20230701'

const INIT_FILTER_OBJ = {
    department: [],
    subDepartment: [],
    grade: [],
    designation: [],
    location: [],
    groupDojFrom: null,
    groupDojTo: null,
    reportingManager: [],
    tabp: [],
    reportType: ["totalNoOfCardExchanged"],
    exitReportType: "avg",
    employeeType: [],
    dateFilterType: "",
    year: "",
    month: [],
    quarter: [],
    finYear: [],
    selectDepartment: false,
    selectAllDepartment: false,
    selectAllSubDepartment: false,
    selectAllGrade: false,
    selectAllDesignation: false,
    selectAllTabp: false,
    selectAllRM: false,
    selectAllReportType: false,
    selectAllEmployeeType: false,
    selectAllMonth: false,
    selectAllQuarter: false,
    selectAllYear: false,
    customFromDate: null,
    customToDate: null,
    minCustomDate: moment(HR_DASHBOARD_START_DATE).format('YYYY-MM-DD'),
    maxCustomDate: moment().format('YYYY-MM-DD'),
    minDojDate: moment(EMP_DOJ_START_DATE).format('YYYY-MM-DD'),
    maxDojDate: moment().format('YYYY-MM-DD'),
    feelingReportType: "consolidated",
    comparisonCriteria: "",
    timeFrame: "",
    selectAllFinYear: false
}

let defaultErrObj = {
    year: "",
    month: "",
    quarter: "",
    finYear: "",
    groupDojFrom: "",
    groupDojTo: "",
    customFromDate: "",
    customToDate: ""
}

const currMon = moment().format("MMMM YYYY")

const maxQuarterMonth = {
    "JFM": 1,
    "AMJ": 4,
    "JAS": 7,
    "OND": 10
}

const defaultDateFilterTypeData = [
    {
        "id": "month",
        "name": "Month"
    },
    {
        "id": "quarter",
        "name": "Quarter"
    },
    {
        "id": "finYear",
        "name": "Financial Year"
    },
    {
        "id": "custom",
        "name": "Customize Date Range"
    }
]

const sessionFilterName = "HR_Dashboard_Report_FilterData"

const COMPARISON_OBJ = {
    comparisonCriteria: "",
    timeFrame: [],
    selectAllTimeFrame: false,
    compareFromDate: null,
    compareToDate: null
}

const defaultCompErrorObj = {
    comparisonCriteria: "",
    timeFrame: "",
    compareFromDate: "",
    compareToDate: ""
}

const isJsonString = (str) => {
    try {
        return JSON.parse(str)
    } catch (e) {
        return false
    }
}

const ReportAnalytics = () => {
    const classes = useStyles()

    const lObj = useLocation()
    const query = new URLSearchParams(lObj.search)
    let uriParams =  query.get("params") || null
    if (uriParams) {
        uriParams = atob(uriParams)
        uriParams = isJsonString(uriParams)
    }
    
    let selectedPageName = uriParams ? uriParams.selectedPageName : false

    const [loading, setLoading] = useState(true)
    const [filterObj, setFilterObj] = useState({ ...INIT_FILTER_OBJ })
    const [reportAnalyticsData, setReportAnalyticsData] = useState({})
    const [isApiError, setIsApiError] = useState(false)
    const [apiErrorMessage, setApiErrorMessage] = useState("")

    const [departmentData, setDepartmentData] = useState([])
    const [subDepartmentData, setSubDepartmentData] = useState([])
    const [gradeData, setGradeData] = useState([])
    const [designationsData, setDesignationsData] = useState([])
    const [branchData, setBranchData] = useState([])
    
    const [reportingManagerData, setReportingManagerData] = useState([])
    const [tabpData, setTabpData] = useState([])
    const [reportTypeData, setReportTypeData] = useState([])
    const [exitReportTypeData, setExitReportTypeData] = useState([])
    
    const [employeeTypeData, setEmployeeTypeData] = useState([])
    const [dateFilterTypeData, setDateFilterTypeData] = useState([])
    let [dateFilterRange, setDateFilterRange] = useState([])
    const [errorObj, setErrorObj] = useState(defaultErrObj)
    const [havingTABPTeam, setHavingTABPTeam] = useState(false)
    const [pageView, setPageView] = useState(selectedPageName ? selectedPageName : "Main")
    const [detailedData, setDetailedData] = useState({})
    const [txnMonth, setTxnMonth] = useState(currMon)
    const [feelingReportTypeData, setFeelingReportTypeData] = useState([])

    const [isDateSelected, setIsDateSelected] = useState(false)
    const [filterLoading, setFilterLoading] = useState(false)
    const [comparisonObj, setComparisonObj] = useState({ ...COMPARISON_OBJ })
    const [compErrorObj, setCompErrorObj] = useState({ ...defaultCompErrorObj })
    const [isCompare, setIsCompare] = useState(0)

    const { toPDF, targetRef } = usePDF()
    
    useEffect(() => {
        async function fetchData () {
            try {

                let fData = { ...filterObj }
                let sessionFilterData =  getSessionFilterData()
                if (sessionFilterData) {
                    fData = { ...sessionFilterData }

                    if (fData.department.length) {
                        await getSubDepartment(fData.department)
                    }

                    if (fData.grade.length) {
                        await getDesignationList(fData.grade)
                    }
                }

                let isFSelected = false
                for (const key in fData) {
                    if (["dateFilterType"].includes(key) && ["finYear", "quarter", "month", "custom"].includes(fData[key])) {
                        isFSelected =  true
                    }
                }

                const response = await getReportFilterInitFetchData()
                if (response.success) {
                    const { reportFilterParams } = response
                    setDateFilterTypeData(reportFilterParams.dateFilterTypeData)
                    setDateFilterRange(reportFilterParams.dateFilterRange)

                    setFilterObj({ ...fData })
                    setIsDateSelected(isFSelected)
                    if (isFSelected) {
                        await fetchDataOnDateSelection({ ...fData })
                        await handleFilters({...fData})
                    }
                } else {
                    setIsApiError(response.success)
                    setApiErrorMessage(response.message)
                }
                
                setLoading(false)
            } catch (err) { console.log(err) }
        }
        
        fetchData()
    }, [])

    const getSessionFilterData = () => {
        let sessionFilterData = window.sessionStorage.getItem(sessionFilterName)
        if (sessionFilterData) {
            sessionFilterData = JSON.parse(sessionFilterData)
            return sessionFilterData
        } else {
            return null
        }
    }

    const initializeStateAfterAPICall = (response) => {
        if (response.success) {
            const { hrDashboardReport } = response
            setReportAnalyticsData(hrDashboardReport)
            if (hrDashboardReport.txnDate) {
                setTxnMonth(moment(hrDashboardReport.txnDate).format("MMMM YYYY"))
            }
            
        }
    }

    const youAreAwesomeDataAfterAPICall = (response) => {
        if (response.success) {
            const { 
                allFilterList,
                allFilterData,
                finalProcessedData,
                reportFilterParams,
                selectedReportType,
                txnDate
            } = response
            setFilterData(allFilterList)
            setDetailedData({ allFilterData, finalProcessedData, selectedReportType })
            setReportTypeData(reportFilterParams.reportTypeData)
            if (txnDate) {
                setTxnMonth(moment(txnDate).format("MMMM YYYY"))
            }
        }
    }

    const obFeedbackDataAfterAPICall = (response) => {
        const { allFilterList, txnDate, allFilterData, finalProcessedData, questionNames, feedbackReport } = response
        setFilterData(allFilterList)
        setDetailedData({ allFilterData, finalProcessedData, questionNames, feedbackReport })
        if (txnDate) {
            setTxnMonth(moment(txnDate).format("MMMM YYYY"))
        }
    }

    const feeingAtHomeDataAfterAPICall = (response) => {
        const { allFilterList, txnDate, feelAtHomeDetailedReport, allFilterData } = response
        setFilterData(allFilterList)
        setDetailedData({ feelAtHomeDetailedReport, allFilterData })
        if (txnDate) {
            setTxnMonth(moment(txnDate).format("MMMM YYYY"))
        }
    }

    const diversityDataAfterAPICall = (response) => {
        const { allFilterList, txnDate, allFilterData, finalProcessedData } = response
        setFilterData(allFilterList)
        setDetailedData({ finalProcessedData, allFilterData })
        if (txnDate) {
            setTxnMonth(moment(txnDate).format("MMMM YYYY"))
        }
    }

    const exitAnalysisDataAfterAPICall = (response) => {
        const { allFilterList, txnDate, allFilterData, finalProcessedData, questionNames, feedbackReport, reportFilterParams, selectedExitReportType, attritionData } = response
        setFilterData(allFilterList)
        setDetailedData({  txnDate, allFilterData, finalProcessedData, questionNames, feedbackReport, selectedExitReportType, attritionData })
        setExitReportTypeData(reportFilterParams.exitReportTypeData)
        if (txnDate) {
            setTxnMonth(moment(txnDate).format("MMMM YYYY"))
        }
    }

    const grossOfferDataAfterAPICall = (response) => {
        const { allFilterList, txnDate, offerRecord } = response
        setFilterData(allFilterList)
        setDetailedData({ txnDate, offerRecord })
        if (txnDate) {
            setTxnMonth(moment(txnDate).format("MMMM YYYY"))
        }
    }

    const netOfferDataAfterAPICall = (response) => {
        const { allFilterList, txnDate, offerRecord } = response
        setFilterData(allFilterList)
        setDetailedData({  txnDate, offerRecord })
        if (txnDate) {
            setTxnMonth(moment(txnDate).format("MMMM YYYY"))
        }
    }

    const getSubDepartment = async (departmentId = []) => {
        try {
            const { data = []} = await getSubDepartmentFetchData({departmentId:departmentId})
            setSubDepartmentData(data)

            return data
        } catch (err) {}
    }

    const getDesignationList = async (gradeName = []) => {
        try {
            const { data = [] } = await getDesignationListByGradeNameFetchData({ gradeName: gradeName , status: ["1", "0"]})
            setDesignationsData(data)
            return data
        } catch(err) {}
    }

    const handleChange = async event => {
        let fObj = { ...filterObj }
        let eObj = { ...errorObj  }
        let isResetFilter = false

        let eventName = event.target.name
        let eventVal = event.target.value

        if (eventName === 'department') {
            let selectedDepartmentId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedDepartmentId = departmentData.map(item => item.department_id)
                selectedDepartmentId = selectedDepartmentId.length === filterObj.department.length ? [] : selectedDepartmentId
            } else {
                for (const item of departmentData) {
                    if (eventVal.includes(item.department_id)) {
                        selectedDepartmentId.push(item.department_id)
                    }
                }
            }
            
            const funcData = await getSubDepartment(selectedDepartmentId)
            fObj.selectAllDepartment = selectedDepartmentId.length ? true : false
            fObj = { ...fObj, [eventName]: selectedDepartmentId }
            let subDept = []
            if (selectedDepartmentId.length && filterObj.subDepartment.length) {
                for (const obj of funcData) {
                    if (filterObj.subDepartment.includes(obj.function_id)) {
                        subDept.push(obj.function_id)
                    }
                }
            }

            fObj.subDepartment = subDept
        }

        if (eventName === 'subDepartment') {
            let selectedSubDepartmentId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedSubDepartmentId = subDepartmentData.map(item => item.function_id)
                selectedSubDepartmentId = selectedSubDepartmentId.length === filterObj.subDepartment.length ? [] : selectedSubDepartmentId
            } else {
                for (const item of subDepartmentData) {
                    if (eventVal.includes(item.function_id)) {
                        selectedSubDepartmentId.push(item.function_id)
                    }
                }
            }
        
            fObj.selectAllSubDepartment = selectedSubDepartmentId.length ? true : false
            fObj = { ...fObj, [eventName]: selectedSubDepartmentId }
        }
    
        if (eventName === 'grade') {
            let selectedGrade = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedGrade = gradeData.map(item => item.grade)
                selectedGrade = selectedGrade.length === filterObj.grade.length ? [] : selectedGrade
            } else {
                for (const item of gradeData) {
                    if (eventVal.includes(item.grade)) {
                        selectedGrade.push(item.grade)
                    }
                }
            }
            
            const desData = await getDesignationList(selectedGrade)
            fObj.selectAllGrade = selectedGrade.length ? true : false
            fObj = { ...fObj, [eventName]: selectedGrade }

            let des = []
            if (selectedGrade.length && filterObj.designation.length) {
                for (const obj of desData) {
                    if (filterObj.designation.includes(obj.designation)) {
                        des.push(obj.designation)
                    }
                }
            }
            fObj.designation = des
        }

        if (eventName === 'designation') {
            let selectedDes = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedDes = designationsData.map(item => item.designation)
                selectedDes = selectedDes.length === filterObj.designation.length ? [] : selectedDes
            } else {
                for (const item of designationsData) {
                    if (eventVal.includes(item.designation)) {
                        selectedDes.push(item.designation)
                    }
                }
            }

            fObj.selectAllDesignation = selectedDes.length ? true : false
            fObj = { ...fObj, [eventName]: selectedDes }
        }

        if (eventName === 'location') {
            let selectedLoc = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedLoc = branchData.map(item => item.id)
                selectedLoc = selectedLoc.length === filterObj.location.length ? [] : selectedLoc
            } else {
                for (const item of branchData) {
                    if (eventVal.includes(item.id)) {
                        selectedLoc.push(item.id)
                    }
                }
            }

            fObj.selectAllLocation = selectedLoc.length ? true : false
            fObj = { ...fObj, [eventName]: selectedLoc }
        }

        if (eventName === 'reportingManager') {
            let selectedRmId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedRmId = reportingManagerData.map(item => item.emp_code)
                selectedRmId = selectedRmId.length === filterObj.reportingManager.length ? [] : selectedRmId
            } else {
                for (const item of reportingManagerData) {
                    if (eventVal.includes(item.emp_code)) {
                        selectedRmId.push(item.emp_code)
                    }
                }
            }

            fObj.selectAllRM = selectedRmId.length ? true : false
            fObj = { ...fObj, [eventName]: selectedRmId }
        }

        if (eventName === 'tabp') {
            let selectedTabpId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedTabpId = tabpData.map(item => item.emp_code)
                selectedTabpId = selectedTabpId.length === filterObj.tabp.length ? [] : selectedTabpId
            } else {
                for (const item of tabpData) {
                    if (eventVal.includes(item.emp_code)) {
                        selectedTabpId.push(item.emp_code)
                    }
                }
            }

            fObj.selectAllTabp = selectedTabpId.length ? true : false
            fObj = { ...fObj, [eventName]: selectedTabpId }
        }

        if (eventName === 'reportType') {
            let selectedReportTypeId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedReportTypeId = reportTypeData.map(item => item.id)
                selectedReportTypeId = selectedReportTypeId.length === filterObj.reportType.length ? [] : selectedReportTypeId
            } else {
                for (const item of reportTypeData) {
                    if (eventVal.includes(item.id)) {
                        selectedReportTypeId.push(item.id)
                    }
                }
            }
            
            if (selectedReportTypeId.length) {
                fObj.selectAllReportType = selectedReportTypeId.length ? true : false
                fObj = { ...fObj, [eventName]: selectedReportTypeId }
            }
        }

        if (eventName === 'exitReportType') {
            fObj = { ...fObj, [eventName]: eventVal }
        }

        if (eventName === 'employeeType') {
            let selectedEmployeeTypeId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedEmployeeTypeId = employeeTypeData.map(item => item.id)
                selectedEmployeeTypeId = selectedEmployeeTypeId.length === filterObj.employeeType.length ? [] : selectedEmployeeTypeId
            } else {
                for (const item of employeeTypeData) {
                    if (eventVal.includes(item.id)) {
                        selectedEmployeeTypeId.push(item.id)
                    }
                }
            }
            
            fObj.selectAllEmployeeType = selectedEmployeeTypeId.length ? true : false
            fObj = { ...fObj, [eventName]: selectedEmployeeTypeId }
        }

        if (eventName === "dateFilterType") {
            eObj = { ...defaultErrObj }
            let defaultObj = {
                finYear: [],
                year: "",
                quarter: [],
                month: [],
                customFromDate: null,
                customToDate: null,
                selectAllFinYear: false,
                selectAllMonth: false,
                selectAllQuarter: false,
                selectAllYear: false,
                feelingReportType: "consolidated"
            }

            fObj = { ...fObj, ...defaultObj,
                [eventName]: eventVal 
            }
            isResetFilter = true
            if (eventVal !== "custom") {
                fObj.customFromDate = null
                fObj.customToDate = null
            }
        }

        if (eventName === "finYear") {
            let selectedFinYearId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedFinYearId = dateFilterRange.finYear.map(item => item.id)
                selectedFinYearId = selectedFinYearId.length === filterObj.finYear.length ? [] : selectedFinYearId
            } else {
                for (const item of dateFilterRange.finYear) {
                    if (eventVal.includes(item.id)) {
                        selectedFinYearId.push(item.id)
                    }
                }
            }

            if (selectedFinYearId.length) {
                eObj[eventName] = ""
            }

            fObj.selectAllFinYear = selectedFinYearId.length ? true : false
            fObj = { ...fObj, [eventName]: selectedFinYearId }
            isResetFilter = true
        }

        if (eventName === "year") {
            if (eventVal) {
                eObj[eventName] = ""
            }
            fObj = { ...fObj, [eventName]: eventVal }
            fObj["month"] = []
            fObj["quarter"] = []
            fObj["selectAllMonth"] = false
            fObj["selectAllQuarter"] = false
            isResetFilter = true
        }

        if (eventName === "quarter") {
            dateFilterRange = removeFutureMonths(fObj)
            let selectedQtrId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedQtrId = dateFilterRange.quarter.map(item => item.id)
                selectedQtrId = selectedQtrId.length === filterObj.quarter.length ? [] : selectedQtrId
            } else {
                for (const item of dateFilterRange.quarter) {
                    if (eventVal.includes(item.id)) {
                        selectedQtrId.push(item.id)
                    }
                }
            }

            if (selectedQtrId.length) {
                eObj[eventName] = ""
            }
            fObj.selectAllQuarter = selectedQtrId.length ? true : false
            fObj = { ...fObj, [eventName]: selectedQtrId }
            isResetFilter = true
        }

        if (eventName === "month") {
            dateFilterRange = removeFutureMonths(fObj)
            let selectedMonthId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedMonthId = dateFilterRange.month.map(item => item.id)
                selectedMonthId = selectedMonthId.length === filterObj.month.length ? [] : selectedMonthId
            } else {
                for (const item of dateFilterRange.month) {
                    if (eventVal.includes(item.id)) {
                        selectedMonthId.push(item.id)
                    }
                }
            }

            if (selectedMonthId.length) {
                eObj[eventName] = ""
            }

            fObj.selectAllMonth = selectedMonthId.length ? true : false
            fObj = { ...fObj, [eventName]: selectedMonthId }
            isResetFilter = true
        }

        if (eventName === "feelingReportType") {
            fObj = { ...fObj, [eventName]: eventVal }
            if (eventVal === "complete") {
                setDetailedData({})
            }
        }

        setErrorObj({ ...eObj })
        setFilterObj({ ...fObj })
        if (isResetFilter) {
            setIsDateSelected(false)
        }
    }

    const fetchDataOnDateSelection = async (fObj) => {
        setFilterLoading(true)
        setIsApiError(false)
        setApiErrorMessage("")
        fObj = {...filterObj, ...fObj}
        const response = await getReportFilterFetchData({ filterObj:fObj, pageView })
        
        if (response.success) {
            const { allFilterList } = response
            setFilterData(allFilterList)
        } else {
            setIsApiError(true)
            setApiErrorMessage(response.message)
        }
        setFilterLoading(false)
    }

    const setFilterData = (allFilterList) => {
        setDepartmentData(allFilterList.departmentData)
        setGradeData(allFilterList.gradeData)
        setBranchData(allFilterList.locationData)
        setReportingManagerData(allFilterList.rmData)
        setTabpData(allFilterList.tabpData)
        setHavingTABPTeam(allFilterList.havingTABPTeam)

        setReportTypeData(allFilterList.reportFilterParams.reportTypeData)
        setExitReportTypeData(allFilterList.reportFilterParams.exitReportTypeData)
        setEmployeeTypeData(allFilterList.reportFilterParams.employeeType)
        setDateFilterTypeData(allFilterList.reportFilterParams.dateFilterTypeData)
        setDateFilterRange(allFilterList.reportFilterParams.dateFilterRange)
        setFeelingReportTypeData(allFilterList.reportFilterParams.feelingReportTypeData)
        setIsDateSelected(true)
    }

    const handleFilters = async (initFObj = null) => {
        resetErrorObj()
        setIsApiError(false)
        setApiErrorMessage("")
        resetComparisonObj()
        let sendObj = initFObj ? { ...initFObj } :  { ...filterObj }
        let isValid = validateDuration(sendObj)
        if (!isValid.error) {
            await handleSubmit(sendObj)
            setIsCompare(1)
        }
        sendObj.feelingReportType = sendObj.feelingReportType === "complete" ? "consolidated" : sendObj.feelingReportType
        window.sessionStorage.setItem(sessionFilterName, JSON.stringify({...sendObj}))
    }

    const handleReset = async () => {
        setLoading(true)
        
        resetErrorObj()
        resetAllFilterData()
        resetAllFilterObj({ isDateExcluded: false })
        resetComparisonObj()

        setIsApiError(false)
        setApiErrorMessage("")
        setIsDateSelected(false)

        if (pageView === "Main") {
            setReportAnalyticsData({})
        }

        setDetailedData({})
        
        setTxnMonth(currMon)
        setLoading(false)

        window.sessionStorage.removeItem(sessionFilterName)
    }

    const handleDateChange = async (date, labelName) => {
        let errMsg = ""
        const dateVal = moment(date).format("DD-MMM-YYYY")
        let isResetFilter = false
        if ((labelName === "customFromDate" && filterObj.customFromDate !== dateVal) || (labelName === "customToDate" && filterObj.customToDate !== dateVal)) {
            isResetFilter = true
        }

        if (isResetFilter) {
            setIsDateSelected(false)
        }

        if (labelName === "groupDojFrom" && filterObj.groupDojTo && moment(filterObj.groupDojTo).isBefore(dateVal)) {
            errMsg = "DOJ (From) date must be less than or equal to DOJ (To) date!"
        } else if (labelName === "groupDojTo" && filterObj.groupDojFrom && moment(filterObj.groupDojFrom).isAfter(dateVal)) {
            errMsg = "DOJ (To) date must be greater than or equal to DOJ (From) date!"
        } else if (labelName === "customFromDate" && filterObj.customToDate && moment(filterObj.customToDate).isBefore(dateVal)) {
            errMsg = "From Date must be less than or equal to To Date!"
        } else if (labelName === "customToDate" && filterObj.customFromDate && moment(filterObj.customFromDate).isAfter(dateVal)) {
            errMsg = "To Date must be greater than or equal to From Date!"
        }

        let newErrObj = {}
        if (errMsg === "") {
            if (["groupDojFrom", "groupDojTo"].includes(labelName)) {
                newErrObj = { groupDojFrom: "",  groupDojTo: "" }
            }
            if (["customFromDate", "customToDate"].includes(labelName)) {
                newErrObj = { customFromDate: "",  customToDate: "" }
            }
        } else  {
            newErrObj = { [labelName]: errMsg }
        }

        setErrorObj({ ...errorObj, ...newErrObj })
        setFilterObj({ ...filterObj, [labelName]: dateVal })
    }

    const handleSubmit = async (sendObj) => {
        setLoading(true)
        let isApiErr = false
        let apiMessage = ""

        if (pageView === "Main") {
            const response = await getReportAnalyticsService({ filterObj: sendObj })
            if (response.success) {
                initializeStateAfterAPICall(response)
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageView === "YouAreAwesome") {
            const response = await getYouAreAwesomeDetailDataService({ filterObj: sendObj, reportName: pageView })
            
            if (response.success) {
                if (sendObj.feelingReportType === "complete") {
                    const { downloadData = {} } = response
                    const { columns = [], content = [] } = downloadData
                    if (content.length) {
                        const htmlData = getReportHtml({ columns, reportData: content })
                        handleDownloadReport(undefined, { fileName:`${pageView}_Complete_Report.xls`, htmlData })
                    } else {
                        ToastElement({
                            msg: `No record to download!`,
                            type: 'error'
                        })
                    }
                } else {
                    youAreAwesomeDataAfterAPICall(response)
                }
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (["FifteenFeedback", "SixtyFeedback"].includes(pageView)) {
            let feedbackType = pageView === "FifteenFeedback" ? "15" : "90"
            const response = await getObFeedbackDetailDataService({ filterObj: sendObj, feedbackType })
            if (response.success) {
                if (sendObj.feelingReportType === "complete") {
                    const { downloadData = {} } = response
                    const { columns = [], content = [] } = downloadData
                    if (content.length) {
                        const htmlData = getReportHtml({ columns, reportData: content })
                        handleDownloadReport(undefined, { fileName:`${(pageView === "FifteenFeedback" ? "15" : "60")}Day_Complete_Report.xls`, htmlData })
                    } else {
                        ToastElement({
                            msg: `No record to download!`,
                            type: 'error'
                        })
                    }
                } else {
                    obFeedbackDataAfterAPICall(response)
                }
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageView === "FeelingAtHome") {
            const response = await getFeelingAtHomeDetailDataService({ filterObj: sendObj, reportName: pageView })
            if (response.success) {
                if (sendObj.feelingReportType === "complete") {
                    const { downloadData = {} } = response
                    const { columns = [], content = [] } = downloadData
                    if (content.length) {
                        const htmlData = getReportHtml({ columns, reportData: content })
                        handleDownloadReport(undefined, { fileName:`Feeling@Home_Complete_Report.xls`, htmlData })
                    } else {
                        ToastElement({
                            msg: `No record to download!`,
                            type: 'error'
                        })
                    }
                } else {
                    feeingAtHomeDataAfterAPICall(response)
                }
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageView === "Diversity") {
            const response = await getDiversityDetailDataService({ filterObj: sendObj })
            if (response.success) {
                if (sendObj.feelingReportType === "complete") {
                    const { downloadData = {} } = response
                    const { columns = [], content = [] } = downloadData
                    if (content.length) {
                        const htmlData = getReportHtml({ columns, reportData: content })
                        handleDownloadReport(undefined, { fileName:`${pageView}_Complete_Report.xls`, htmlData })
                    } else {
                        ToastElement({
                            msg: `No record to download!`,
                            type: 'error'
                        })
                    }
                } else {
                    diversityDataAfterAPICall(response)
                }
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageView === "ExitAnalysis") {
            const response = await getExitAnalysisDetailDataService({ filterObj: sendObj, reportName: pageView })
            if (response.success) {
                if (sendObj.feelingReportType === "complete") {
                    const { downloadData = {} } = response
                    const { columns = [], content = [] } = downloadData
                    if (content.length) {
                        const htmlData = getReportHtml({ columns, reportData: content })
                        handleDownloadReport(undefined, { fileName:`${pageView}_Complete_Report.xls`, htmlData })
                    } else {
                        ToastElement({
                            msg: `No record to download!`,
                            type: 'error'
                        })
                    }
                } else {
                    exitAnalysisDataAfterAPICall(response)
                }
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageView === "GrossOffer") {
            const response = await getGrossOfferDataService({ filterObj: sendObj })
            if (response.success) {
                grossOfferDataAfterAPICall(response)
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageView === "NetOffer") {
            const response = await getNetOfferDataService({ filterObj: sendObj })
            if (response.success) {
                netOfferDataAfterAPICall(response)
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        }

        if (isApiErr) {
            setIsApiError(isApiErr)
            setApiErrorMessage(apiMessage)
        } else {
            setIsDateSelected(true)
        }
        setLoading(false)
    }

    const validateDuration = (obj) => {
        const { 
            dateFilterType, 
            customFromDate, 
            customToDate, 
            quarter = [], 
            finYear,
            month = [], 
            year,
            groupDojFrom,
            groupDojTo, 
            reportType = []
        } = obj 

        let result = { error: false, message: "" }
        let errObj = {}
        const dateFilterTypeList = defaultDateFilterTypeData.map(obj => obj.id)
        
        let section = dateFilterType
        if (dateFilterTypeList.includes(dateFilterType)) {
            switch (dateFilterType) {
                case "month":
                    if (!year) {
                        section = "year"
                        result.error = true
                        result.message = "Please select year!"
                    } else if (!month.length) {
                        result.error = true
                        result.message = "Please select month!"
                    }
                    break
                case "quarter":
                    if (!year) {
                        section = "year"
                        result.error = true
                        result.message = "Please select year!"
                    } else if (!quarter.length) {
                        result.error = true
                        result.message = "Please select quarter!"
                    }
                    break
                case "finYear":
                    if (!finYear.length) {
                        result.error = true
                        result.message = "Please select financial year!"
                    }
                    break
                case "custom":
                    if (!customFromDate) {
                        section = "customFromDate"
                        result.error = true
                        result.message = "Please select from date!"
                    } else if (!customToDate) {
                        section = "customToDate"
                        result.error = true
                        result.message = "Please select to date!"
                    } else if (customToDate && moment(customToDate).isBefore(moment(customFromDate))) {
                        section = "customToDate"
                        result.error = true
                        result.message = "To Date must be greater than or equal to From Date!"
                    }
                    break
                default:
                    break
            }
        } else {
            section = "dateFilterType"
            result.error = true
            result.message = "Select date filter type!"
        }

        if (groupDojTo && moment(groupDojTo).isBefore(moment(groupDojFrom))) {
            section = "groupDojTo"
            result.error = true
            result.message = "DOJ (To) date must be greater than or equal to DOJ (From) date!"
        }

        errObj = { [section]: result.message }
        if (pageView === "YouAreAwesome" && !reportType.length) {
            errObj = { ...errObj, reportType: "Report Type can't be blank!" }
        }
        
        setErrorObj({ ...errorObj, ...errObj })
        return result
    }

    const handleDetailedData = async (pageName, isBack =  false) => {
        setLoading(true)
        setPageView(pageName)
        let filterData =  { ...filterObj }

        if (isBack) {
            resetComparisonObj()
            const sessionFilterData = getSessionFilterData()
            if (sessionFilterData) {
                filterData = { ...sessionFilterData }
            }
            filterData.feelingReportType = "consolidated"
        }

        let apiMessage = ""
        let isApiErr = false

        let pageParams = getUrlParams({ pageName, isBack })
        window.parent.postMessage(`${pageParams}`, process.env.REACT_APP_BASE_URL) 

        if (pageName !== "YouAreAwesome") {
            setReportTypeData([])
        }

        if (pageName !== "ExitAnalysis") {
            setExitReportTypeData([])
        }

        if (pageName !== "FeelingAtHome") {
            setFeelingReportTypeData([])
        }
        
        
        if (pageName === "Main") {
            const response = await getReportAnalyticsService({ filterObj: {...filterData } })
            if (response.success) {
                initializeStateAfterAPICall(response)
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageName === "YouAreAwesome") {
            const response = await getYouAreAwesomeDetailDataService({ filterObj: filterData, reportName: pageName })
            if (response.success) {
                youAreAwesomeDataAfterAPICall(response)
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageName === "FifteenFeedback") {
            const response = await getObFeedbackDetailDataService({ filterObj: filterData, feedbackType: "15" })
            if (response.success) {
                obFeedbackDataAfterAPICall(response)
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageName === "SixtyFeedback") {
            const response = await getObFeedbackDetailDataService({ filterObj: filterData, feedbackType: "90" })
            if (response.success) {
                obFeedbackDataAfterAPICall(response)
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageName === "FeelingAtHome") {
            const response = await getFeelingAtHomeDetailDataService({ filterObj: filterData, reportName: pageName })
            if (response.success) {
                feeingAtHomeDataAfterAPICall(response)
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageName === "Diversity") {
            const response = await getDiversityDetailDataService({ filterObj })
            if (response.success) {
                diversityDataAfterAPICall(response)
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageName === "ExitAnalysis") {
            const response = await getExitAnalysisDetailDataService({ filterObj, reportName: pageName })
            if (response.success) {
                exitAnalysisDataAfterAPICall(response)
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageName === "GrossOffer") {
            const response = await getGrossOfferDataService({ filterObj })
            if (response.success) {
                grossOfferDataAfterAPICall(response)
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        } else if (pageName === "NetOffer") {
            const response = await getNetOfferDataService({ filterObj })
            if (response.success) {
                netOfferDataAfterAPICall(response)
            } else {
                isApiErr = true
                apiMessage = response.message
            }
        }
        

        if (isApiErr) {
            setIsApiError(isApiErr)
            setApiErrorMessage(apiMessage)
        }

        setFilterObj({ ...filterData })
        setLoading(false)
    }

    const getUrlParams = ({ pageName, isBack = false }) => {
        let uriParams = {}
        
        if (pageName && !isBack) {
            uriParams = { ...uriParams, selectedPageName: pageName }
        }
        
        return Object.keys(uriParams).length ? btoa(JSON.stringify(uriParams)) : ""
    }

    const handleSearch = async () => {
        setIsDateSelected(false)
        resetAllFilterData()
        resetComparisonObj()
        const defaultFbj = resetAllFilterObj({ isDateExcluded: true })
        resetErrorObj()
        resetComparisonObj()

        if (pageView === "Main") {
            setReportAnalyticsData({})
        }
        setDetailedData({})
        let isValid = validateDuration(filterObj)
        if (!isValid.error) {
            window.sessionStorage.setItem(sessionFilterName, JSON.stringify({...defaultFbj}))
            await fetchDataOnDateSelection({...defaultFbj})
            await handleFilters({...defaultFbj})
        }
    }

    const resetAllFilterObj = ({ isDateExcluded = false }) => {
        let defaultFbj = { ...INIT_FILTER_OBJ }
        if (isDateExcluded) {
            const excludedList = ["customFromDate", "customToDate", "dateFilterType", "finYear", "month", "quarter", "year", "selectAllMonth", "selectAllQuarter", "selectAllYear", "selectAllFinYear"]
            for (const key of excludedList) {
                defaultFbj[key] = filterObj[key]
            }
        }
        setFilterObj({ ...defaultFbj })

        return defaultFbj
    }

    const resetAllFilterData = () => {
        setDepartmentData([])
        setSubDepartmentData([])
        setGradeData([])
        setDesignationsData([])
        setBranchData([])
    }

    const resetErrorObj = () => {
        setErrorObj({ ...defaultErrObj })
        setCompErrorObj({ ...defaultCompErrorObj })
    }

    const getPageName = (pageName) => {
        let name = ""
        switch (pageName) {
            case "YouAreAwesome":
                name = "You Are Awesome"
                break
            case "FifteenFeedback":
                name = "15 Days Feedback"
                break
            case "SixtyFeedback":
                name = "60 Days Feedback"
                break
            case "FeelingAtHome":
                name = "Feeling @Home"
                break
            case "Diversity":
                name = "Diversity"
                break
            case "ExitAnalysis":
                name = "Exit Analysis"
                break
            case "GrossOffer":
                name = "Gross Offer"
                break
            case "NetOffer":
                name = "Net Offer"
                break
            default:
                name = ""
                break
        }

        return name
    }

    const removeFutureMonths = (filterObj) => {
        const currentDate = moment().format("YYYYMMDD")
    
        const { year } = filterObj
        const currYear = currentDate.slice(0, 4)
        const currMon = +currentDate.slice(4, 6)
        const isCurrentYear = currYear === year

        let customDateFilterRange = {}

        for (const key in dateFilterRange) {
            if (!customDateFilterRange[key]) {
                customDateFilterRange[key] = []
            }

            for (const obj of dateFilterRange[key]) {
                switch (key) {
                    case "quarter":
                        if (year === "2023" && ["JFM", "AMJ"].includes(obj.id)) {
                            continue
                        }

                        if (isCurrentYear && maxQuarterMonth[obj.id] > currMon) {
                            continue
                        }

                        customDateFilterRange[key].push(obj)
                        break
                    case "month":
                        if (year === "2023" && ["01", "02", "03", "04", "05", "06"].includes(obj.id)) {
                            continue
                        }

                        if (isCurrentYear && +obj.id > currMon) {
                            continue
                        }
                        customDateFilterRange[key].push(obj)
                        break
                    default:
                        customDateFilterRange[key].push(obj)
                        break
                }
            } 
        }

        return customDateFilterRange
    }

    const downloadPdf = (e) => {
        e.preventDefault()
        toPDF({ filename: `Report_Analysis_${moment().format("DDMMYYYYHHmmss")}.pdf`, page: { margin: Margin.LARGE } })
    }

    const handleCompareData = (event) => {
        let eventName = event.target.name
        let eventVal = event.target.value

        let cObj = { ...comparisonObj  }
        let cEObj = { ...compErrorObj }
        if (eventName === 'comparisonCriteria') {
            cObj = { ...cObj, [eventName]: eventVal, "timeFrame": [], selectAllTimeFrame: false }
            if (eventVal) {
                cEObj[eventName] = ""
            }
        }

        if (eventName === 'timeFrame') {
            let selectedTimeFrameId = []
            const timeFrameList = dateFilterRange[comparisonObj.comparisonCriteria]
            if (eventVal[eventVal.length - 1] === "all") {
                selectedTimeFrameId = timeFrameList.map(item => item.id)
                selectedTimeFrameId = selectedTimeFrameId.length === cObj.timeFrame.length ? [] : selectedTimeFrameId
            } else {
                for (const item of timeFrameList) {
                    if (eventVal.includes(item.id)) {
                        selectedTimeFrameId.push(item.id)
                    }
                }
            }
            
            cObj.selectAllTimeFrame = selectedTimeFrameId.length ? true : false
            cObj = { ...cObj, [eventName]: selectedTimeFrameId }
            if (eventVal.length) {
                cEObj[eventName] = ""
            }
        }
        
        setCompErrorObj({ ...cEObj })
        setComparisonObj({ ...cObj })
    }

    const handleComparison = async () => {
        setLoading(true)
        let eObj = { ...defaultCompErrorObj }
        let isValid = true
        if (!comparisonObj.comparisonCriteria) {
            eObj.comparisonCriteria = `Select Comparison Criteria!`
            isValid = false
        }

        if (filterObj.dateFilterType === "finYear" && !comparisonObj.timeFrame.length) {
            eObj.timeFrame = `Select Time Frame For Comparison!`
            isValid = false
        } else if (["custom"].includes(comparisonObj.comparisonCriteria)) {
            if (!comparisonObj.compareFromDate) {
                eObj.compareFromDate = `Select Compare From Date!`
                isValid = false
            }

            if (!comparisonObj.compareToDate) {
                eObj.compareToDate = `Select Compare To Date!`
                isValid = false
            }

            if (comparisonObj.compareFromDate && comparisonObj.compareToDate && moment(comparisonObj.compareToDate).isBefore(comparisonObj.compareFromDate )) {
                eObj.compareToDate =  "Compare To Date must be less than or equal to Compare From Date!"
                isValid = false
            }
        }

        setCompErrorObj({ ...eObj })
        if (isValid) {
            const yearList = getComparisonDates({ filterObj: { ...filterObj }, comparisonObj: { ...comparisonObj } })
            const { tabularRecord = {}, totalRecord = {} } = await fetchCompareData({ pageView, filterParams: { ...filterObj }, comparisonObj, yearList })
            
            setDetailedData({ ...tabularRecord, ...totalRecord })
        }
        setLoading(false)
    }

    const handleCompareDateChange = async (date, labelName) => {
        let errMsg = ""
        const dateVal = moment(date).format("DD-MMM-YYYY")

        if (labelName === "compareFromDate" && comparisonObj.compareToDate && moment(comparisonObj.compareToDate).isBefore(dateVal)) {
            errMsg = "From Date must be less than or equal to To Date!"
        } else if (labelName === "compareToDate" && comparisonObj.compareFromDate && moment(comparisonObj.compareFromDate).isAfter(dateVal)) {
            errMsg = "To Date must be greater than or equal to From Date!"
        }

        let newErrObj = {}
        if (errMsg === "") {
            if (["compareFromDate", "compareToDate"].includes(labelName)) {
                newErrObj = { compareFromDate: "",  compareToDate: "" }
            }
        } else  {
            newErrObj = { [labelName]: errMsg }
        }

        setCompErrorObj({ ...compErrorObj , ...newErrObj })
        setComparisonObj({ ...comparisonObj, [labelName]: dateVal })
    }

    const resetComparisonObj = () => {
        setComparisonObj({ ...COMPARISON_OBJ })
    }

    const { 
        youAreAwesomeReport,
        fifteenFeedbackReport,
        sixtyFeedbackReport,
        feelAtHomeReport,
        diversityReport,
        exitFeedbackRecord,
        // grossOfferRecord,
        // netOfferRecord
    } = reportAnalyticsData
    const reportAnalyticsDataLen = Object.keys(reportAnalyticsData).length
    const detailedDataLen = Object.keys(detailedData).length

    return (<React.Fragment>
            <Helmet>
                <title>HR Dashboard: Analytics</title>
            </Helmet>
                <div className={classes.root}>
                    <Grid container id="main-container-grid">
                        <Grid container className={classes.cardHeader}>
                            <Grid xs={4} item style={{ padding: "14 0 0 14" }}>
                                <label className={classes.headerDateText}>{txnMonth}</label>
                            </Grid>
                            <Grid xs={8} item style={{ paddingLeft: "10%"}}>
                                <h3 className={classes.headerText}>HR Dashboard Analytics</h3>
                            </Grid>
                        </Grid>
                        {
                            pageView !== "Main" && <HeaderLayout
                            title={getPageName(pageView)}
                            subTitle={""}
                            icon={<PeopleOutlineTwoTone/>}
                            redirectUrl={null}
                        /> 
                        }
                        <div className={classes.cardBody}>
                            <div className={classes.filterBody}>
                                <FilterReport 
                                    handleSubmit={handleSubmit}
                                    departmentData={departmentData}
                                    subDepartmentData={subDepartmentData}
                                    gradeData={gradeData}
                                    designationsData={designationsData}
                                    branchData={branchData}
                                    reportingManagerData={reportingManagerData}
                                    tabpData={tabpData}
                                    reportTypeData={reportTypeData}
                                    employeeTypeData={employeeTypeData}
                                    dateFilterTypeData={dateFilterTypeData}
                                    dateFilterRange={dateFilterRange}
                                    loading = {loading}
                                    filterObj={filterObj}
                                    handleChange={handleChange}
                                    handleFilters={handleFilters}
                                    handleReset={handleReset}
                                    handleDateChange={handleDateChange}
                                    errorObj={errorObj}
                                    havingTABPTeam={havingTABPTeam}
                                    isDateSelected={isDateSelected}
                                    filterLoading={filterLoading}
                                    handleSearch={handleSearch}
                                    isApiError={isApiError}
                                    apiErrorMessage={apiErrorMessage}
                                    exitReportTypeData={exitReportTypeData}
                                    removeFutureMonths={removeFutureMonths}
                                    feelingReportTypeData={feelingReportTypeData}
                                    comparisonObj={comparisonObj}
                                    handleComparison={handleComparison}
                                    handleCompareData={handleCompareData}
                                    compErrorObj={compErrorObj}
                                    pageView={pageView}
                                    handleCompareDateChange={handleCompareDateChange}
                                    isCompare={isCompare}
                                />
                            </div>
                            {(!isDateSelected || loading || (pageView === "Main" && !reportAnalyticsDataLen) || (pageView !== "Main" && !detailedDataLen)) ? 
                                (filterObj.feelingReportType === "complete" && !loading ?
                                    (<MainDivOnDownload 
                                        message="Click the Download button above to obtain the complete report."
                                        handleDetailedData={handleDetailedData}
                                        pageView={pageView}
                                    />) 
                                    : (filterObj.feelingReportType !== "complete" && !loading ? 
                                        (<MainDivOnDownload pageView={pageView} message="No record to display!" handleDetailedData={handleDetailedData} />) : <Loader loading={loading} />)
                                )
                                : 
                                <React.Fragment>
                                { (reportAnalyticsDataLen && pageView === "Main") ? <React.Fragment>
                                <Grid style={{ textAlign: "right", padding: "10px" }}>
                                    <Button style={{ width: "140px", height: "27px", padding: "0 0 0 0", textTransform: "capitalize" }} variant="contained" color="primary" title="Download Report" onClick={e => downloadPdf(e)}><span>Download Report</span></Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href={`${process.env.REACT_APP_BASE_URL}/HR_Dashboard_Report_Analytics_Readme.pdf`} rel="noreferrer" target="_blank"><Button style={{ width: "75px", height: "27px", padding: "0 0 0 0", textTransform: "capitalize" }} variant="outlined" color="primary" title="Help File"><span>Help File</span></Button></a>
                                </Grid>
                                <div className={classes.maiBody}>
                                    <Grid container spacing={3} ref={targetRef} style={{ padding: "0 5 0 5" }}>
                                        <Grid xs={6} sm={6} md={6} item className={classes.graphDiv}>
                                            <FeelingAtHomeCard 
                                                feelAtHomeReport={feelAtHomeReport}
                                                handleDetailedData={handleDetailedData}
                                            />

                                            <DiversityCard
                                                diversityReport={diversityReport}
                                                handleDetailedData={handleDetailedData}
                                            />

                                            <FifteenFeedbackCard 
                                                break={true} 
                                                wrap={false}
                                                fifteenFeedbackReport={fifteenFeedbackReport}
                                                handleDetailedData={handleDetailedData}
                                            />
                                        </Grid>
                                        <Grid xs={6} sm={6} md={6} item className={classes.graphDiv}>
                                            <YouAreAwesomeCard 
                                                youAreAwesomeReport={youAreAwesomeReport}
                                                handleDetailedData={handleDetailedData}
                                                youAreAwesomeCols={youAreAwesomeCols} 
                                            />
                                            <ExitAnalysisCard 
                                                exitFeedbackRecord={exitFeedbackRecord}
                                                handleDetailedData={handleDetailedData}
                                            />
                                            <Grid item xs={2} sm={2} md={2}></Grid>

                                            <SixtyFeedbackCard 
                                                sixtyFeedbackReport={sixtyFeedbackReport}
                                                handleDetailedData={handleDetailedData}
                                            />
                                        </Grid>

                                        {/* <NetOfferCard 
                                            netOfferRecord={netOfferRecord}
                                            handleDetailedData={handleDetailedData}
                                        /> */}
                                        {/* <GrossOfferCard 
                                            grossOfferRecord={grossOfferRecord}
                                            handleDetailedData={handleDetailedData}
                                        /> */}
                                    </Grid>
                                </div></React.Fragment> : (null)}
                            
                            {(pageView === "YouAreAwesome" && detailedDataLen) ? <div className={classes.maiBody}>
                                <YouAreAwesomeData 
                                    detailedData={detailedData}
                                    reportTypeData={reportTypeData}
                                    youAreAwesomeCols={youAreAwesomeCols}
                                    handleDetailedData={handleDetailedData}
                                    
                                /></div> : (null)}
                            {(pageView === "FifteenFeedback" && detailedDataLen) ? <div className={classes.maiBody}>
                                <FifteenFeedbackData 
                                    detailedData={detailedData}
                                    handleDetailedData={handleDetailedData}
                                    
                                /></div> : (null)}
                            {(pageView === "SixtyFeedback" && detailedDataLen) ? <div className={classes.maiBody}>
                                <SixtyFeedbackData 
                                    detailedData={detailedData}
                                    handleDetailedData={handleDetailedData}
                                    
                                /></div> : (null)}
                            {(pageView === "FeelingAtHome" && detailedDataLen) ? <div className={classes.maiBody}>
                                <FeelingAtHomeData 
                                    detailedData={detailedData}
                                    handleDetailedData={handleDetailedData}
                                    
                                /></div> : (null)}
                            {(pageView === "Diversity" && detailedDataLen) ? <div className={classes.maiBody}>
                                <DiversityData 
                                    detailedData={detailedData}
                                    handleDetailedData={handleDetailedData}
                                    
                                />
                                </div> : (null)}
                            {(pageView === "ExitAnalysis" && detailedDataLen) ? <div className={classes.maiBody}>
                                <ExitAnalysisData 
                                    detailedData={detailedData}
                                    handleDetailedData={handleDetailedData}
                                    
                                />
                                </div> : (null)}
                            {(pageView === "GrossOffer" && detailedDataLen) ? <div className={classes.maiBody}>
                                <GrossOfferData 
                                    detailedData={detailedData}
                                    handleDetailedData={handleDetailedData}
                                />
                                </div> : (null)}
                            {(pageView === "NetOffer" && detailedDataLen) ? <div className={classes.maiBody}>
                            <NetOfferData 
                                detailedData={detailedData}
                                handleDetailedData={handleDetailedData}
                            />
                                </div> : (null)}
                            </React.Fragment>
                            }
                        </div>
                    </Grid>
                </div>                
        </React.Fragment>)
}

export default ReportAnalytics