import React,{ Component } from 'react'
import {AllEmployeeTagging} from '../../pages/EmployeeTagging/AllEmployeeTagging'
import {EmployeeTaggingSummary} from '../../pages/EmployeeTagging/EmployeeTaggingSummary'
export default class EmployeeTaggingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
         path : props.computedMatch.params.type
    };
}
  getPage(path){
    switch(path){
      case "Summary":
        return EmployeeTaggingSummary
      default:
        return AllEmployeeTagging
    }
  }
  render (){
    const Component = this.getPage(this.state.path)
    
    return (
      <div>
        <Component {...this.props}/>
      </div>
    )
  }
}
