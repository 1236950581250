

export const getReportHtml = ({ columns = [], reportData = [], totalRow = [], firstRow  = null}) => {
    let html = `<html><body><table border="1">`
    
    if (firstRow) {
        html += `<tr>
                <td style="text-align: left;" colspan="${columns.length}">
                    <b>Total Number Of Exits - ${firstRow.exitCount}</b><br>
                    <b>Attrition % - ${firstRow.attritionPercent}</b><br>
                    <b>Early Attrition % - ${firstRow.earlyAttritionPercent}</b>
                </td>
            </tr>`
    }

    html += `<tr>`

    for (const col of columns) {
        html += `<td style="text-align: left;"><b>${col.label || ''}</b></td>`
    }
    html += `</tr>`

    for (const row of reportData) {
        html += `<tr>`
        for (const col of columns) {
            html += `<td style="text-align: left;">${getCellData(row[col.id])}</td>`
        }
        html += `</tr>`
    }

    if (reportData.length > 1 && totalRow.length) {
        html += `<tr>`
        for (const tRow of totalRow) {
            for (const col of columns) {
                html += `<td style="text-align: left;"><b>${getCellData(tRow[col.id])}</b></td>`
            }
        }
        html += `</tr>`
    }
    
    html+='</table></body></html>'
    return html
}

function getCellData(data) {
    return (data === null || data === undefined ? "" : data)
}

export const handleDownloadReport = async (e = undefined, { fileName, htmlData }) => {
    if (e) {
        e.preventDefault()
    }
    
    const blob = new Blob([htmlData], {
        type: 'data:application/vnd.ms-excel;base64'
    })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
}